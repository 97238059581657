import attributes from "./attributes";

/**
 * Grid
 *
 * L'état initial du tableau de résultat.
 *
 * / ! \ Penser à mettre une view par défaut pour ne pas casser
 *       l'affichage du chip 'changedColumns' dans les résultats de recherche
 *
 * / ! \ Penser à mettre un displayTime par défaut au colonne de type date pour
 *       ne pas casser l'affichage du chip 'changedColumns' dans les résultats
 *       de recherche
 *
 */
const grid = [
    {
        id: "067",
        colId: attributes.merchant_order_id.id,
        displayed: true,
        view: "l",
    },
    {
        id: "060",
        colId: attributes.mix_payment.id,
        displayed: true,
        view: "s",
    },
    {
        id: "041",
        colId: attributes.customer_email.id,
        displayed: true,
        view: "l",
    },
    {
        id: "119",
        colId: attributes.account_number.id,
        displayed: true,
        view: "s",
    },
    {
        id: "101",
        colId: attributes.sentinel_score.id,
        displayed: true,
        view: "s",
    },
    {
        id: "103",
        colId: attributes.sentinel_result.id,
        displayed: true,
        view: "s",
    },
    {
        id: "100",
        colId: attributes.sentinel_review_result.id,
        displayed: true,
        view: "s",
    },
    {
        id: "073",
        colId: attributes.card_country.id,
        displayed: true,
        view: "s",
    },
    {
        id: "037",
        colId: attributes.customer_account_number.id,
        displayed: true,
        view: "l",
    },
    {
        id: "071",
        colId: attributes.auth.id,
        displayed: true,
        view: "l",
    },
    {
        id: "070",
        colId: attributes.auth_method.id,
        displayed: true,
        view: "s",
    },
    {
        id: "075",
        colId: attributes.payment_means.id,
        displayed: true,
        view: "l",
    },
    {
        id: "010",
        colId: attributes.amount.id,
        displayed: true,
        displayCurrency: true,
        view: "l",
    },
    {
        id: "110",
        colId: attributes.status.id,
        displayed: true,
        view: "l",
    },
    {
        id: "012",
        colId: attributes.balance.id,
        displayed: true,
        displayCurrency: true,
        view: "l",
    },
    {
        id: "034",
        colId: attributes.created_date.id,
        displayed: false,
        displayTime: false,
        view: "l",
    },
    {
        id: "035",
        colId: attributes.custom_data.id,
        displayed: false,
        view: "l",
    },
    {
        id: "009",
        colId: attributes.authorized_by_payment_means_date.id,
        displayed: true,
        displayTime: true,
        view: "l",
    },
    {
        id: "065",
        colId: attributes.order_date.id,
        displayed: false,
        displayTime: false,
        view: "l",
    },
    {
        id: "076",
        colId: attributes.payment_method.id,
        displayed: false,
        view: "l",
    },
    {
        id: "097",
        colId: attributes.sentinel_reviewer.id,
        displayed: false,
        view: "l",
    },
    {
        id: "093",
        colId: attributes.refunded_amount.id,
        displayed: false,
        displayCurrency: true,
        view: "l",
    },
    {
        id: "046",
        colId: attributes.phone_number.id,
        displayed: false,
        view: "l",
    },
    {
        id: "043",
        colId: attributes.ip_address.id,
        displayed: false,
        view: "l",
    },
    {
        id: "135",
        colId: attributes.card_category.id,
        displayed: false,
        view: "l",
    },
    {
        id: "074",
        colId: attributes.card_token.id,
        displayed: false,
        view: "l",
    },
    {
        id: "059",
        colId: attributes.issuer_financial_institution_country.id,
        displayed: false,
        view: "l",
    },
    {
        id: "061",
        colId: attributes.issuer_name.id,
        displayed: false,
        view: "l",
    },
    {
        id: "044",
        colId: attributes.ip_country.id,
        displayed: false,
        view: "l",
    },
    {
        id: "109",
        colId: attributes.trxid.id,
        displayed: false,
        view: "l",
    },
    {
        id: "032",
        colId: attributes.cms_name.id,
        displayed: false,
        view: "l",
    },
    {
        id: "104",
        colId: attributes.settled_date.id,
        displayed: false,
        displayTime: false,
        view: "l",
    },
    {
        id: "107",
        colId: attributes.store_name.id,
        displayed: false,
        view: "l",
    },
    {
        id: "055",
        colId: attributes.external_reference.id,
        displayed: false,
        view: "l",
    },
    {
        id: "056",
        colId: attributes.iban_creditor.id,
        displayed: false,
        view: "l",
    },
    {
        id: "057",
        colId: attributes.iban_debitor.id,
        displayed: false,
        view: "l",
    },
    {
        id: "064",
        colId: attributes.operation_date.id,
        displayed: false,
        displayTime: false,
        view: "l",
    },
    {
        id: "077",
        colId: attributes.operation_amount.id,
        displayed: false,
        displayCurrency: true,
        view: "l",
    },
    {
        id: "080",
        colId: attributes.operation_type.id,
        displayed: false,
        view: "l",
    },
    {
        id: "079",
        colId: attributes.operation_status.id,
        displayed: false,
        view: "l",
    },
    {
        id: "066",
        colId: attributes.order_currency.id,
        displayed: true,
        view: "s",
    },
    {
        id: "053",
        colId: attributes.basket_item_count.id,
        displayed: false,
        view: "l",
    },
    {
        id: "004",
        colId: attributes.account_name.id,
        displayed: false,
        view: "l",
    },
    {
        id: "006",
        colId: attributes.mid.id,
        displayed: false,
        view: "l",
    },
    {
        id: "045",
        colId: attributes.customer_name.id,
        displayed: false,
        view: "l",
    },
    {
        id: "007",
        colId: attributes.acquirer_trxid.id,
        displayed: false,
        view: "l",
    },
    {
        id: "078",
        colId: attributes.payment_operation_mode.id,
        displayed: false,
        view: "l",
    },
    {
        id: "136",
        colId: attributes.card_type.id,
        displayed: false,
        view: "l",
    },
    {
        id: "047",
        colId: attributes.shipping_country.id,
        displayed: false,
        view: "l",
    },
    {
        id: "058",
        colId: attributes.financial_institution.id,
        displayed: false,
        view: "l",
    },
    {
        id: "240",
        colId: attributes.refund_reasons.id,
        displayed: false,
        view: "l",
    },
    {
        id: "090",
        colId: attributes.refusal_reasons.id,
        displayed: false,
        view: "m",
    },
    {
        id: "247",
        colId: attributes.card_id.id,
        displayed: false,
        view: "l",
    },
    {
        id: "026",
        colId: attributes.captured_date.id,
        displayed: false,
        displayTime: false,
        view: "l",
    },
    {
        id: "246",
        colId: attributes.captured_amount.id,
        displayed: false,
        displayCurrency: true,
        view: "l",
    },
    {
        id: "241",
        colId: attributes.retraction_delay.id,
        displayed: false,
        view: "s",
    },
    {
        id: "272",
        colId: attributes.cancel_author.id,
        displayed: false,
        view: "l",
    },
    {
        id: "268",
        colId: attributes.cancel_reason.id,
        displayed: false,
        view: "l",
    },
    {
        id: "242",
        colId: attributes.author.id,
        displayed: false,
        view: "l",
    },
    {
        id: "092",
        colId: attributes.refunded_date.id,
        displayed: false,
        displayTime: false,
        view: "l",
    },
    {
        id: "259",
        colId: attributes.remittance_reference.id,
        displayed: false,
        withFormating: true,
        view: "l",
    },
    {
        id: "270",
        colId: attributes.operation_id.id,
        displayed: false,
        view: "l",
    },
    {
        id: "273",
        colId: attributes.dsp2_status.id,
        displayed: true,
        view: "l",
    },
    {
        id: "025",
        colId: attributes.business_name.id,
        displayed: false,
        view: "l",
    },
    {
        id: "277",
        colId: attributes.chargeback_id.id,
        displayed: false,
        view: "l",
    },
    {
        id: "248",
        colId: attributes.chargeback_reason.id,
        displayed: false,
        view: "l",
    },
    {
        id: "276",
        colId: attributes.chargeback_type.id,
        displayed: false,
        view: "l",
    },
    {
        id: "286",
        colId: attributes.recurring_payment.id,
        displayed: false,
        view: "s",
    },
    {
        id: "243",
        colId: attributes.refund_method.id,
        displayed: false,
        view: "m",
    },
    {
        id: "033",
        colId: attributes.collected_date.id,
        displayed: false,
        displayTime: false,
        view: "l",
    },
    {
        id: "054",
        colId: attributes.eci.id,
        displayed: false,
        view: "l",
    },
    {
        id: "334",
        colId: attributes.eci_out.id,
        displayed: false,
        view: "l",
    },
    {
        id: "238",
        colId: attributes.customer_device.id,
        displayed: false,
        view: "l",
    },
    {
        id: "278",
        colId: attributes.customer_operating_system.id,
        displayed: false,
        view: "l",
    },
    {
        id: "274",
        colId: attributes.three_ds_v2_attempt.id,
        displayed: false,
        view: "l",
    },
    {
        id: "287",
        colId: attributes.operation_merchant_id.id,
        displayed: false,
        view: "l",
    },
    {
        id: "030",
        colId: attributes.chargeback_date.id,
        displayed: false,
        displayTime: false,
        view: "l",
    },
    {
        id: "288",
        colId: attributes.debit_agreement_id.id,
        displayed: false,
        view: "l",
    },
    {
        id: "264",
        colId: attributes.customer_mandate_id.id,
        displayed: false,
        view: "l",
    },
    {
        id: "309",
        colId: attributes.sca_preference.id,
        displayed: false,
        view: "l",
    },
    {
        id: "310",
        colId: attributes.sca_source.id,
        displayed: false,
        view: "l",
    },
    {
        id: "069",
        colId: attributes.authentication_indicator.id,
        displayed: false,
        view: "l",
    },
    {
        id: "324",
        colId: attributes.order_amount.id,
        displayed: false,
        displayCurrency: true,
        view: "l",
    },
    {
        id: "313",
        colId: attributes.customer_city.id,
        displayed: false,
        view: "l",
    },
    {
        id: "040",
        colId: attributes.billing_country.id,
        displayed: false,
        view: "l",
    },
    {
        id: "312",
        colId: attributes.billing_postcode.id,
        displayed: false,
        view: "l",
    },
    {
        id: "311",
        colId: attributes.billing_street.id,
        displayed: false,
        view: "l",
    },
    {
        id: "316",
        colId: attributes.shipping_city.id,
        displayed: false,
        view: "l",
    },
    {
        id: "315",
        colId: attributes.shipping_postcode.id,
        displayed: false,
        view: "l",
    },
    {
        id: "314",
        colId: attributes.shipping_street.id,
        displayed: false,
        view: "l",
    },
    {
        id: "279",
        colId: attributes.dispute_date.id,
        displayed: false,
        view: "l",
    },
    {
        id: "280",
        colId: attributes.dispute_reason.id,
        displayed: false,
        view: "l",
    },
    {
        id: "333",
        colId: attributes.same_day_refund.id,
        displayed: false,
        view: "s",
    },
    {
        id: "285",
        colId: attributes.one_click.id,
        displayed: false,
        view: "s",
    },
    {
        id: "005",
        colId: attributes.acquirer.id,
        displayed: false,
        view: "l",
    },
    {
        id: "086",
        colId: attributes.provider.id,
        displayed: false,
        view: "l",
    },
    {
        id: "327",
        colId: attributes.provider_mid.id,
        displayed: false,
        view: "l",
    },
    {
        id: "326",
        colId: attributes.provider_trxid.id,
        displayed: false,
        view: "l",
    },
    {
        id: "089",
        colId: attributes.acquirer_return_code.id,
        displayed: false,
        view: "m",
    },
    {
        id: "088",
        colId: attributes.provider_return_code.id,
        displayed: false,
        view: "m",
    },
    {
        id: "008",
        colId: attributes.acquirer_connection_id.id,
        displayed: false,
        view: "l",
    },
    {
        id: "354",
        colId: attributes.acquirer_chargeback_date.id,
        displayed: false,
        view: "l",
    },
    {
        id: "094",
        colId: attributes.route_reference.id,
        displayed: false,
        view: "l",
    },
    {
        id: "234",
        colId: attributes.fraud_reported_by_acquirer.id,
        displayed: false,
        view: "s",
    },
    {
        id: "236",
        colId: attributes.fraud_reported_by_acquirer_date.id,
        displayed: false,
        view: "l",
    },
    {
        id: "042",
        colId: attributes.customer_id.id,
        displayed: false,
        view: "m",
    },
    {
        id: "346",
        colId: attributes.chargeback_amount.id,
        displayed: false,
        displayCurrency: false,
        view: "l",
    },
    {
        id: "347",
        colId: attributes.chargeback_refund_amount.id,
        displayed: false,
        displayCurrency: false,
        view: "l",
    },
    {
        id: "355",
        colId: attributes.provider_return_message.id,
        displayed: false,
        view: "m",
    },
    {
        id: "356",
        colId: attributes.acquirer_return_message.id,
        displayed: false,
        view: "m",
    },
    {
        id: "391",
        colId: attributes.is_acquirer_tra_activated.id,
        displayed: false,
        view: "s",
    },
    {
        id: "392",
        colId: attributes.three_dsv2_integration.id,
        displayed: false,
        view: "m",
    },
    {
        id: "052",
        colId: attributes.device_id.id,
        displayed: false,
        view: "m",
    },
];

export default grid;

/**
 * Attribute on which group if sorted
 * - only date by now
 * @type {string}
 */
export const groupByAttribute = "created_date";

/**
 * Default sort params
 */
export const defaultSortParams = {
    order_by: "created_date",
    direction: "desc",
};
