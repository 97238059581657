import { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import keycode from "keycode";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Hidden from "@mui/material/Hidden";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import { CellNumeric, CellText } from "../../../../../common/components/Table/Cell";
import { useP } from "../../../../../services/i18n";
import { useModule } from "../../../../../services/module";

export const useStylesRowLayout = makeStyles((theme) => ({
    row: {
        display: "inline-flex",
        width: "100%",
        ...theme.typography.body3,
        overflow: "hidden",
        whiteSpace: "nowrap",
        lineHeight: "32px",
        minHeight: 32,
    },
    cellLabel: {
        width: 158,
        alignSelf: "flex-start",
        textAlign: "left",
    },
    cellBody: {
        display: "flex",
        alignSelf: "stretch",
        width: "calc(100% - 158px)",
        paddingLeft: 8,
        minHeight: 32,
    },
    cellBodyNoLabel: {
        display: "flex",
        width: "100%",
        minHeight: 32,
    },
    label: {
        width: 158,
        overflow: "hidden",
        textOverflow: "ellipsis",
        margin: "auto 0",
        whiteSpace: "nowrap",
    },
    description: {
        margin: 0,
        width: 158,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    body: {
        margin: "auto 0",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    actionCell: {
        minHeight: 32,
        minWidth: 72,
        display: "flex",
        alignSelf: "flex-start",
        textAlign: "right",
    },
    actionIcon: {
        width: 24,
        height: 24,
    },
    expanded: {
        whiteSpace: "pre-wrap",
        height: "inherit",
    },
}));

export function RowLayout(props) {
    const { actions, attribute, children, hideLabel, expanded, description, customLabel } = props;

    const classes = useStylesRowLayout(props);
    const p = useP();
    const { id: module } = useModule();
    const userSettings = useSelector((state) => state.app.settings.data);

    const handleKeyDown = useCallback((actionOnClick) => {
        return (event) => {
            if (!["enter", "space"].includes(keycode(event))) {
                return;
            }
            actionOnClick();
            event.preventDefault();
        };
    }, []);

    let label = customLabel;
    let title = customLabel;
    if (!hideLabel && !label && !title) {
        label = p.has(`attributes.${module}.${attribute.id}.noticeLabel`)
            ? p.t(`attributes.${module}.${attribute.id}.noticeLabel`)
            : p.t(`attributes.${module}.${attribute.id}.label`);
        title = p.has(`attributes.${module}.${attribute.id}.noticeTitle`)
            ? p.t(`attributes.${module}.${attribute.id}.noticeTitle`)
            : p.t(`attributes.${module}.${attribute.id}.label`);
    }

    return (
        <div
            className={classNames(classes.row, { [classes.expanded]: expanded })}
            id={`attribute-row-${attribute.id}`}
        >
            {hideLabel || (
                <div
                    className={classes.cellLabel}
                    key={`attribute-row-${attribute.id}-cell-label`}
                    id={`attribute-row-${attribute.id}-cell-label`}
                >
                    <div
                        className={description ? classes.description : classes.label}
                        children={label}
                        title={title}
                    />
                </div>
            )}

            <div
                className={hideLabel ? classes.cellBodyNoLabel : classes.cellBody}
                key={`attribute-row-${attribute.id}-cell-body`}
                id={`attribute-row-${attribute.id}-cell-body`}
            >
                <div className={classes.body} children={children} />

                <Hidden mdDown>
                    {actions &&
                        actions.map((action) => {
                            let actionEl;
                            switch (action.type) {
                                case "icon":
                                    actionEl = (
                                        <HiIconButton
                                            className={classes.actionIcon}
                                            name={action.name}
                                            onClick={action.onClick}
                                        >
                                            <HiIcon size={24} icon={action.icon} />
                                        </HiIconButton>
                                    );
                                    break;
                                case "numeric":
                                    actionEl = (
                                        <CellNumeric
                                            value={Number(action.value)}
                                            currency={action.currency}
                                            precision={action.precision}
                                            locale={userSettings.language}
                                        />
                                    );
                                    break;
                                case "text":
                                    actionEl = (
                                        <CellText
                                            label={action.label}
                                            value={action.value}
                                            color={action.color}
                                        />
                                    );
                                    break;
                                default:
                                    actionEl = null;
                                    break;
                            }

                            if (actionEl) {
                                const style = {
                                    cursor: "pointer",
                                    ...action.style,
                                };

                                return (
                                    <div
                                        id={`attribute-row-${attribute.id}-cell-action-${action.name}`}
                                        key={`attribute-row-${attribute.id}-cell-action-${action.name}`}
                                        className={classes.actionCell}
                                        onClick={action.onClick}
                                        style={style}
                                        onKeyDown={handleKeyDown(action.onClick)}
                                        tabIndex={0}
                                    >
                                        {actionEl}
                                    </div>
                                );
                            }

                            return null;
                        })}
                </Hidden>
            </div>
        </div>
    );
}

RowLayout.propTypes = {
    /**
     * Label to display instead of attribute label (usefull for custom_data)
     */
    customLabel: PropTypes.string,
    /**
     * Do not display ellipsis and adapt cell's height
     */
    expanded: PropTypes.bool,
    /**
     * Do not display label
     */
    hideLabel: PropTypes.bool,
};

RowLayout.defaultProps = {
    actions: [],
    hideLabel: false,
};
